<template>
	<div>
		<div class="form-group">
			<label>{{ $t("mouvement.destination") }}<sup>*</sup></label>
            <div class="mb-2">
				<div class="mb-2">
						<button class="btn mr-2" :class="handleButtonData == 'address' ? 'btn-primary':'btn-secondary'" @click="HandleButton('address')">{{ $t("mouvement.CarnetAdresse") }}</button>
						<button class="btn btn-secondary mr-2" @click="HandleButton('addAddress')" >{{ $t("mouvement.AddAdresse") }}<font-awesome-icon :icon="['far', 'plus']" /></button>
		                <button v-if="hasFacturationAccess" class="btn mr-2" :class="handleButtonData == 'linkTiers' ? 'btn-primary':'btn-secondary'" @click="HandleButton('linkTiers')" >{{ $t("mouvement.LinkTier") }}</button>
						<button v-if="hasFacturationAccess" class="btn btn-secondary" @click="HandleButton('addTiers')" >{{ $t("mouvement.AddTier") }}<font-awesome-icon :icon="['far', 'plus']" /></button>
		        </div>
				<div v-if="handleButtonData == 'linkTiers'">
					<SearchTiers 
                    :tiers_selected.sync="tiers"
					:preselected="tiers"
					/>
				</div>
				<div v-if="handleButtonData == 'address'">
					<div class="row">
						<div class="col">
							<e-select
								track-by="lieu_id"
								label="lieu_label"
								v-model="lieu"
								:options="residences"
								:placeholder="$t('mouvement.rechercher_lieu')"
								:loading="isLoadingLieu"
								:sortable="false"
								@input="setLieu"
								ref="input"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
								<template slot="option" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
							</e-select>
						</div>
					</div>
				</div>
            </div>
	    </div>

	    <div class="form-group">
			<label>{{ $t("mouvement.date_mouvement") }}<sup>*</sup></label>
            <e-datepicker id="date_ouverture" v-model="date" />
		</div>

		<div class="form-group">
			<div class="custom-control custom-checkbox">
				<input type="checkbox" class="custom-control-input" id="realise" v-model="retour_checked">
				<label class="custom-control-label" for="realise">{{ $t("mouvement.definir_date_retour") }}</label>
			</div>
		</div>

		<div class="form-group" v-if="retour_checked">
			<label>{{ $t("mouvement.date_retour") }} <sup>*</sup></label>
			<e-datepicker v-model="retour" />
		</div>

		<div class="form-group">
			<div class="custom-control custom-checkbox">
				<input type="checkbox" class="custom-control-input" id="sortie_def" v-model="sortie">
				<label class="custom-control-label" for="sortie_def">{{ $t("mouvement.sortie_definitive") }}</label>
			</div>
		</div>

        <div class="form-group">
			<label>{{ $t("mouvement.raison_mouvement") }}</label>
			<input class="form-control" type="text" id="mouvement_raison" :placeholder="$t('mouvement.raison_mouvement')" v-model="raison" required>
		</div>

		<div class="form-group">
            <label class="col-form-label">{{ $t('mouvement.choix_contact') }}</label>
			<div class="row">
				<div class="col">
					<e-select
						v-model="contact"
						id="contact_id"
						label="contact_label"
						track-by="contact_id"
						:placeholder="$t('acte.selectionnez_contact')"
						:selectedLabel="$t('global.selected_label')"
						:options="contacts"
						:allow-empty="true"
						:loading="isLoadingContact"
						:show-labels="false"
						:group-select="false"
						@input="setContact"
					>
						<template slot="option" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
				<div class="col-auto">
					<button class="btn btn-secondary" @click="addContact"><font-awesome-icon :icon="['far', 'plus']" /></button>
				</div>
			</div>
        </div>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from "EventBus"
	import Lieux from '@/mixins/Lieux'
	import Contact from "@/mixins/Contact.js"
	import ShutterMouvements from '@/mixins/shutters-manager/Mouvements.js'
	import Shutter from "@/mixins/Shutter.js"

	export default {
		name: 'WriteSortie',
		props: ['destination_lieu', 'mouvement_date', 'selected_contact', 'mouvement_raison', 'date_retour', 'sortie_definitive', 'date_retour_checked'],
		mixins: [Lieux, Contact, Shutter, ShutterMouvements],
		data () {
			return { 
				all_lieux: [],
				residences: [],
				lieu: {},
				isLoadingLieu: false,
				date: new Date(),
	            contact: null,
	            contacts: [],
	            isLoadingContact: false,
	            raison: '',
            	tiers: {},
				retour: null,
				sortie: false,
				retour_checked: false,
				handleButtonData: 'address',
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.provenance = this.provenance_lieu
				this.lieu = this.destination_lieu
				this.tiers = {
					tiers_address1: this.lieu.lieu_adresse,
					tiers_postalcode: this.lieu.lieu_cp,
					tiers_rs: this.lieu.lieu_label,
					tiers_town: this.lieu.lieu_ville,
					tiers_id: this.lieu.tiers_id
				}
				this.date = this.mouvement_date
				this.contact = this.selected_contact
				this.raison = this.mouvement_raison
				this.retour = this.date_retour
				this.sortie = this.sortie_definitive
				this.retour_checked = this.date_retour_checked

	            this.isLoadingLieu = true
	            this.all_lieux = await this.getLieux(false)
	            this.residences = this.all_lieux.filter(lieu => lieu.lieu_type !== 1 && lieu.lieu_type !== 5)
	            this.isLoadingLieu = false

	            this.isLoadingContact = true
	            this.contacts = await this.getAllContact(true)
	            this.isLoadingContact = false

				EventBus.$on('RefreshAllLieux', this.lieuFormSubmit)
			},
			HandleButton(action){
				if (action == 'addTiers'){
					this.handleButtonData = 'linkTiers'
					this.openAddTier()
				} else if(action == 'addAddress'){
					this.handleButtonData = 'address'
					this.openAjoutLieu("sortie") 

				} else {
					this.handleButtonData = action
				}

			},
			addLieu() {
	            this.openAjoutLieu()
	        },
			setLieu() {
				return this.$emit('update:destination_lieu', this.lieu)
			},
			setContact() {
				return this.$emit('update:selected_contact', this.contact)
			},
			async lieuFormSubmit() {
	            this.isLoadingLieu = true
	            this.all_lieux = await this.getLieux(false)
	            this.residences = this.all_lieux.filter(lieu => lieu.lieu_type !== 1 && lieu.lieu_type !== 5)
	            this.isLoadingLieu = false
	        },
			addContact() {
	            this.openAjoutContact()
	        },
			async contactFormSubmit() {
				this.isLoadingContact = true
				this.contacts = await this.getAllContact(true)
	            this.isLoadingContact = false
	        },
		},

	    computed: {
	        hasFacturationAccess() {
	            return this.$store.state.userAccess.hasFacturationAccess
	        },
	    },

		watch: {
			'date' (val) {
				return this.$emit('update:mouvement_date', val)
			},
			'raison' (val) {
				return this.$emit('update:mouvement_raison', val)
			},
			'retour' (val) {
				return this.$emit('update:date_retour', val)
			},
			'sortie' (val) {
				return this.$emit('update:sortie_definitive', val)
			},
			'retour_checked' (val) {
				this.retour = null
			},
	        'tiers' (val) {
	            if(val) {
	                this.lieu = {
	                    lieu_adresse: val.tiers_address1,
	                    lieu_cp: val.tiers_postalcode,
	                    lieu_label: val.tiers_rs,
	                    lieu_ville: val.tiers_town,
	                    lieu_type: 3,
	                    tiers_id: val.tiers_id
	                }
	                this.setLieu()
	            }
	        },
		},

		components: {
        	SearchTiers : () => import('@/components/Contract/SearchTiers'),
		}
		
	}

</script>